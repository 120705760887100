@import url(https://fonts.googleapis.com/css?family=Vollkorn&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500&family=IBM+Plex+Serif&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
  font-family: "fira mono";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content_content__1Ou2P {
  flex: 1 1;
  margin-right: 5%;
  margin-bottom: 3%;
  border: 1px solid rgb(192, 192, 192);
  max-width: 210px;
  width: 210px;
  height: 210px;
  padding-left: 10px;
  color: #06069a;
}

.content_content__1Ou2P:hover {
  flex: 1 1;
  background-color: #06069a;
  color: white;
  border-radius: 50%;
  max-width: 210px;
  width: 210px;
  height: 210px;
  padding-left: 10px;
  border: 1px solid rgb(192, 192, 192);
}

.content_content__1Ou2P h1 {
  width: 200px;
  font-size: 30px;
  font-weight: normal;
  line-height: 1em;
  font-family: "Vollkorn";
}

.content_content__1Ou2P:hover h1 {
  padding: 0px;
  margin-left: -20px;
  margin-top: -5px;
  width: 200px;
  font-size: 100px;
  font-weight: normal;
  line-height: 0.8em;
  font-family: "fira mono";
  word-wrap: break-word;
}

.content_content__1Ou2P h3 {
  font-size: 12px;
  line-height: 1em;
  font-weight: normal;
}

.content_body__1vms5 {
  padding-left: 10vw;
  line-height: 1.4rem;
}

/* .title {
  margin-top: 5%;
  margin-left: 5%;
  font-family: Vollkorn;
  position: fixed; 
  z-index: 1;
  top: 0; 
}
.title a {
  margin-left: 5%;
  padding: 10px 0px 0px 0px;
  text-decoration: none;
  font-size: 10px;
  color: #000;
  text-align: right;
}

.leftnav {
  height: 100%;
  width: 150px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  overflow: auto;
}

.leftnav a {
  margin-left: 5%;
  padding: 10px 0px 0px 0px;
  text-decoration: none;
  font-size: 11px;
  color: #000;
  text-align: right;
  font-family: "Fira Mono";
} */

/* @media only screen and (max-width: 1050px) { */

.navbar_title__3-RtQ {
  margin-top: 60px;
  margin-left: 5vw;
  font-family: Vollkorn;
  position: fixed;
  z-index: 1;
  top: 0;
}
.navbar_title__3-RtQ a {
  margin-left: 5%;
  padding: 10px 0px 0px 0px;
  text-decoration: none;
  font-size: 13px;
  color: #000;
  text-align: right;
}

.navbar_leftnav__2jeUW {
  padding-top: 50px;
  height: 100%;
  width: 13vw;
  min-width: 135px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: auto;
  display: inline-block;
}

.navbar_leftnav__2jeUW a {
  padding: 10px 0px 0px 0px;
  margin-left: 20px;
  text-decoration: none;
  font-size: 11px;
  color: rgb(46, 46, 46);
  display: block;
  text-align: right;
  font-family: "Fira Mono";
}

.navbar_bottomnav__3ofoo {
  padding-bottom: 15%;
  width: 13vw;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  overflow: auto;
  display: inline-block;
}

.navbar_bottomnav__3ofoo a {
  padding: 10px 0px 0px 0px;
  text-decoration: none;
  font-size: 11px;
  color: rgb(46, 46, 46);
  display: block;
  text-align: right;
  font-family: "Fira Mono";
}

/* } */

.pages_container__1SyjK {
  padding-left: 10vw;
  margin-left: 50px;
  margin-top: 3%;
  flex-direction: column;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 10%;
}

.pages_flexGrid__zyWC_ {
  display: flex;
  flex-wrap: wrap;
}

.pages_flexGrid2__1eW3U {
  display: flex;
  flex-wrap: wrap;
  margin-right: 2%;
  margin-top: 2%;
}

.pages_title__1UVfo {
  margin-left: 10%;
  margin-top: 5%;
  margin-bottom: 3%;
}

.pages_button__3ytkl {
  border: 1px solid rgb(68, 68, 68);
  color: rgb(46, 46, 46);
  border-radius: 2rem;
  font-size: 14px;
  font-family: "fira mono";
  background-color: white;
}

.pages_inversebutton__3rVEV {
  border: 1px solid white;
  background-color: #06069a;
  color: white;
  border-radius: 2rem;
  font-size: 14px;
  font-family: "fira mono";
}

.pages_input__S_VY- {
  padding-top: "15px";
  padding-left: "5px";
  padding-bottom: "5px";
  border: "none";
  font-size: "15px";
  border-bottom: "2px solid #757575";
  background-color: "#f9f9f9";
}

/* @media only screen and (max-width: 600px) {
    .flexGrid {
    display: block;
    justify-content: center;
    }
} */

.editor_iframe__21QtW {
  position: absolute;
  top: 10%;
  left: 250px;
  right: 0;
  bottom: 0;
  padding-top: 20px;
}

.editor_textarea__owe1E {
  width: 99.5%;
  height: 500px;
  border: none;
  background-color: #06069a;
  color: white;
  overflow-y: scroll;
}

