.content {
  flex: 1;
  margin-right: 5%;
  margin-bottom: 3%;
  border: 1px solid rgb(192, 192, 192);
  max-width: 210px;
  width: 210px;
  height: 210px;
  padding-left: 10px;
  color: #06069a;
}

.content:hover {
  flex: 1;
  background-color: #06069a;
  color: white;
  border-radius: 50%;
  max-width: 210px;
  width: 210px;
  height: 210px;
  padding-left: 10px;
  border: 1px solid rgb(192, 192, 192);
}

.content h1 {
  width: 200px;
  font-size: 30px;
  font-weight: normal;
  line-height: 1em;
  font-family: "Vollkorn";
}

.content:hover h1 {
  padding: 0px;
  margin-left: -20px;
  margin-top: -5px;
  width: 200px;
  font-size: 100px;
  font-weight: normal;
  line-height: 0.8em;
  font-family: "fira mono";
  word-wrap: break-word;
}

.content h3 {
  font-size: 12px;
  line-height: 1em;
  font-weight: normal;
}

.body {
  padding-left: 10vw;
  line-height: 1.4rem;
}
