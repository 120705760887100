.container {
  padding-left: 10vw;
  margin-left: 50px;
  margin-top: 3%;
  flex-direction: column;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 10%;
}

.flexGrid {
  display: flex;
  flex-wrap: wrap;
}

.flexGrid2 {
  display: flex;
  flex-wrap: wrap;
  margin-right: 2%;
  margin-top: 2%;
}

.title {
  margin-left: 10%;
  margin-top: 5%;
  margin-bottom: 3%;
}

.button {
  border: 1px solid rgb(68, 68, 68);
  color: rgb(46, 46, 46);
  border-radius: 2rem;
  font-size: 14px;
  font-family: "fira mono";
  background-color: white;
}

.inversebutton {
  border: 1px solid white;
  background-color: #06069a;
  color: white;
  border-radius: 2rem;
  font-size: 14px;
  font-family: "fira mono";
}

.input {
  padding-top: "15px";
  padding-left: "5px";
  padding-bottom: "5px";
  border: "none";
  font-size: "15px";
  border-bottom: "2px solid #757575";
  background-color: "#f9f9f9";
}

/* @media only screen and (max-width: 600px) {
    .flexGrid {
    display: block;
    justify-content: center;
    }
} */
