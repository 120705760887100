/* .title {
  margin-top: 5%;
  margin-left: 5%;
  font-family: Vollkorn;
  position: fixed; 
  z-index: 1;
  top: 0; 
}
.title a {
  margin-left: 5%;
  padding: 10px 0px 0px 0px;
  text-decoration: none;
  font-size: 10px;
  color: #000;
  text-align: right;
}

.leftnav {
  height: 100%;
  width: 150px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  overflow: auto;
}

.leftnav a {
  margin-left: 5%;
  padding: 10px 0px 0px 0px;
  text-decoration: none;
  font-size: 11px;
  color: #000;
  text-align: right;
  font-family: "Fira Mono";
} */

/* @media only screen and (max-width: 1050px) { */

.title {
  margin-top: 60px;
  margin-left: 5vw;
  font-family: Vollkorn;
  position: fixed;
  z-index: 1;
  top: 0;
}
.title a {
  margin-left: 5%;
  padding: 10px 0px 0px 0px;
  text-decoration: none;
  font-size: 13px;
  color: #000;
  text-align: right;
}

.leftnav {
  padding-top: 50px;
  height: 100%;
  width: 13vw;
  min-width: 135px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: auto;
  display: inline-block;
}

.leftnav a {
  padding: 10px 0px 0px 0px;
  margin-left: 20px;
  text-decoration: none;
  font-size: 11px;
  color: rgb(46, 46, 46);
  display: block;
  text-align: right;
  font-family: "Fira Mono";
}

.bottomnav {
  padding-bottom: 15%;
  width: 13vw;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  overflow: auto;
  display: inline-block;
}

.bottomnav a {
  padding: 10px 0px 0px 0px;
  text-decoration: none;
  font-size: 11px;
  color: rgb(46, 46, 46);
  display: block;
  text-align: right;
  font-family: "Fira Mono";
}

/* } */
